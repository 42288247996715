<template>
  <div class="relative flex bg-midnight p-8">
    <div class="text-steel flex flex-col m-auto">
      <div class="text-center">
        <p>Alex Moreira</p>
      </div>
      <div
        class="mx-auto mt-2 text-2xl justify-around space-x-16 md:space-x-12 sm:space-x-8"
      >
        <a
          href="https://github.com/alexbmoreira"
          target="_blank"
          class="text-center transition duration-400 ease-in-out hover:text-snow"
        >
          <i class="fab fa-github"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/alexbmoreira"
          target="_blank"
          class="text-center transition duration-400 ease-in-out hover:text-snow"
        >
          <i class="fab fa-linkedin"></i>
        </a>
        <a
          href="https://storage.googleapis.com/alexbmoreira-site/Alex_Moreira_Resume.pdf"
          target="_blank"
          class="text-center transition duration-400 ease-in-out hover:text-snow"
        >
          <i class="fas fa-file-pdf"></i>
        </a>
        <a
          href="mailto:alexbmoreira@gmail.com"
          class="text-center transition duration-400 ease-in-out hover:text-snow"
        >
          <i class="fas fa-envelope"></i>
        </a>
        <a
          href="tel:+14164336886"
          class="text-center transition duration-400 ease-in-out hover:text-snow"
        >
          <i class="fas fa-phone-alt"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  }
};
</script>

<style></style>

<template>
  <div id="particles-js" class="h-screen"></div>
</template>

<script>
export default {
  name: "ParticlesJS",
  mounted() {
    require("particles.js");
    this.$nextTick(() => {
      this.initParticlesJS();
    });
  },
  methods: {
    initParticlesJS() {
      /* eslint-disable */
            particlesJS('particles-js', {
                'particles': {
                'number': {
                    'value': 80,
                    'density': {
                        'enable': true,
                        'value_area': 3500
                    }
                },
                'color': {
                    'value': '#ffffff'
                },
                'shape': {
                    'type': 'circle',
                    'stroke': {
                    'width': 0,
                    'color': '#000000'
                    },
                    'polygon': {
                    'nb_sides': 5
                    },
                    'image': {
                    'src': 'img/github.svg',
                    'width': 100,
                    'height': 100
                    }
                },
                'opacity': {
                    'value': 0.4,
                    'random': false,
                    'anim': {
                    'enable': false,
                    'speed': 1,
                    'opacity_min': 0.1,
                    'sync': false
                    }
                },
                'size': {
                    'value': 5,
                    'random': true,
                    'anim': {
                    'enable': false,
                    'speed': 80,
                    'size_min': 0.1,
                    'sync': false
                    }
                },
                'line_linked': {
                    'enable': true,
                    'distance': 300,
                    'color': '#ffffff',
                    'opacity': 0.3,
                    'width': 2
                },
                'move': {
                    'enable': true,
                    'speed': 1,
                    'direction': 'none',
                    'random': false,
                    'straight': false,
                    'out_mode': 'out',
                    'bounce': false,
                    'attract': {
                    'enable': false,
                    'rotateX': 600,
                    'rotateY': 1200
                    }
                }
                },
                'interactivity': {
                'detect_on': 'canvas',
                'events': {
                    'onhover': {
                    'enable': true,
                    'mode': 'repulse'
                    },
                    'onclick': {
                    'enable': false,
                    'mode': 'push'
                    },
                    'resize': true
                },
                'modes': {
                    'grab': {
                        'distance': 800,
                        'line_linked': {
                            'opacity': 1
                        }
                    },
                    'bubble': {
                        'distance': 800,
                        'size': 80,
                        'duration': 2,
                        'opacity': 0.8,
                        'speed': 3
                    },
                        'repulse': {
                            'distance': 100,
                            'duration': 0.4
                    },
                    'push': {
                        'particles_nb': 4
                    },
                    'remove': {
                        'particles_nb': 2
                    }
                }
                },
                'retina_detect': true
            })
            }
        }
    }
</script>

<style>
/* Your styles */
</style>